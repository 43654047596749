<template>
  <div class="accordion">
    <div class="accordion_header">
      <div class="media">
        <div class="media-content">
          <div class="columns is-multiline is-mobile">
            <div class="column is-2-desktop is-6-mobile">
              <p class="title_media text_18 has-text-weight-bold text_primary_green">Nº. DE COTIZACIÓN</p>
              <p class="text_16">{{ detailQuote.quote_number }}</p>
            </div>
            <div class="column is-3-desktop is-6-mobile">
              <p class="title_media text_18 has-text-weight-bold text_primary_green">COTIZACIÓN REALIZADA</p>
              <p class="text_16">{{ formatDate(detailQuote.created_at, 'DD/MM/YYYY') }}</p>
            </div>
            <div class="column is-2-desktop is-6-mobile">
              <p class="title_media text_18 has-text-weight-bold text_primary_green">ENVIAR A</p>
              <p class="text_16">{{ detailQuote.name }} {{ detailQuote.last_name }}</p>
            </div>
            <div class="column is-2-desktop is-6-mobile">
              <p class="title_media text_18 has-text-weight-bold text_primary_green">TELÉFONO</p>
              <p class="text_16">{{ detailQuote.phone }}</p>
            </div>
            <div class="column is-3-desktop is-6-mobile">
              <p class="title_media text_18 has-text-weight-bold text_primary_green">CORREO ELECTRÓNICO</p>
              <p class="text_16">{{ detailQuote.email }}</p>
            </div>
          </div>
        </div>
        <div class="media-right">
          <span class="material-icons keyboard_arrow_icon" @click="accordionActive = !accordionActive">
            {{ accordionActive ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </span>
        </div>
      </div>
    </div>
    <div class="accordion_block" v-if="accordionActive">
      <div class="columns is-multiline">
        <div class="column is-3">
          <p class="title_media text_18 has-text-weight-bold text_primary_green">EMPRESA</p>
          <p class="text_16">{{ detailQuote.company || 'Sin información' }}</p>
        </div>
        <div class="column is-3">
          <p class="title_media text_18 has-text-weight-bold text_primary_green">SITIO WEB</p>
          <p class="text_16">{{ detailQuote.website || 'Sin información' }}</p>
        </div>
        <div class="column is-6">
          <p class="title_media text_18 has-text-weight-bold text_primary_green">COMENTARIO</p>
          <p class="text_16">{{ detailQuote.special_instructions || 'Sin información' }}</p>
        </div>
      </div>
    </div>
    <div class="accordion_footer is_grid" v-if="accordionActive">
      <div class="table-container">
        <table class="table is-fullwidth">
          <tbody>
            <tr v-for="product in productsQuote" :key="product.id">
              <th>
                <figure class="image is-96x96">
                  <img :src="`${ASSETS_URL}${product.main_image}`" :alt="product.product_name">
                  <div
                    class="sale_content"
                    v-if="product.variable_name && product.variable_promotion_discount || !product.variable_name && product.promotion_discount"
                  >
                    {{ product.variable_name ? product.variable_promotion_discount : product.promotion_discount }} %
                  </div>
                </figure>
              </th>
              <th>
                <p class="pd_name mb-2"> {{ product.product_name }} </p>
                <p class="pd_desc has-text-weight-normal mb-5"> {{ product.variable_name }}
                  <span class="pd_desc has-text-weight-bold">{{ product.variable_name ? product.variable_catalogue : product.product_catalogue }}</span>
                </p>
              </th>
              <th>
                <div class="is-flex is-align-items-center">
                  <p class="text_20 mr-2"> X{{ product.total_products }} </p>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins/formats'

export default {
  name: 'AccordionQuotationDetail',
  mixins: [mixins],
  data () {
    return {
      accordionActive: false,
      ASSETS_URL: process.env.VUE_APP_URL_FILES
    }
  },
  props: {
    detailQuote: {
      type: Object,
      default: () => {}
    },
    productsQuote: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
  .accordion {
    border-radius: 18px;
    background-color: #ffffff;
  }
  .accordion_header {
    padding: 20px 40px 20px 30px;
  }
  .accordion_block {
    padding: 20px 40px 20px 30px;
    border-top: solid 1px #b5b5b5A6;
  }
  .accordion_footer {
    padding: 20px 0px 0px 0px;
  }
  .keyboard_arrow_icon {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    font-size: 28px;
    font-weight: 600;
    color: var(--primary-green);
  }

  .text_16 {
    color: var(--primary-black);
  }

  .media {
    align-items: center;
  }

  .title_media {
    margin-bottom: 1rem;
    letter-spacing: 0.2px;
  }

  .table {
    background-color: transparent;
  }
  .table th {
    border-right: solid 1px #b5b5b5A6;
    vertical-align: middle;
  }
  .table th:last-child {
    border-right: none;
  }
  .table th:first-child {
    border-left: none;
  }
  .table tr:first-child {
    border-top: solid 1px #b5b5b5A6;
  }
  .table tr {
    border-top: solid 1px #b5b5b5A6;
  }
  .table tr:last-child {
    border-bottom: none;
  }

  figure.image {
    display: block;
    margin: auto;
  }

  .sale_content {
    background-color: var(--orange);
    color: white;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.15;
    padding: 5px;
    position: absolute;
    top: 0px;
    right: 0;
  }

  .pd_name {
    color: #253423;
  }
  .pd_desc {
    font-size: 14px;
    line-height: 1.14;
  }
  .text_discount {
    color: var(--orange);
  }
</style>
